<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      dense
      class="mb-5"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Cidades das Unidades</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-form
      ref="form"
    >
      <v-row
        justify="center"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="10"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="city.city"
            :rules="$rules.required"
            required
            outlined
            dense
            label="Cidade *"
          />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="10"
          md="12"
          sm="12"
        >
          <v-textarea
            v-model="city.htmlPoligon"
            :rules="$rules.required"
            required
            outlined
            dense
            label="HTML Poligono*"
            no-resize
            rows="5"
          />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="10"
          md="12"
          sm="12"
        >
          <v-textarea
            v-model="city.cityNameHtml"
            :rules="$rules.required"
            required
            outlined
            dense
            label="Nome Cidade HTML*"
            no-resize
            rows="5"
          />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="10"
          md="12"
          sm="12"
        >
          <v-textarea
            v-model="city.locationPinoHtml"
            :rules="$rules.required"
            required
            outlined
            dense
            label="Localização Pino HTML*"
            no-resize
            rows="5"
          />
        </v-col>
      </v-row>
    </v-form>

    <v-row
      class="mt-10"
      justify="center"
      no-gutters
    >
      <v-card-actions>
        <v-btn
          color="secondary"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          :loading="isLoading"
          @click="submit"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-row>
  </v-card>
</template>

<script>
import UnitsCityService from '../../../services/units-city.service';

export default {
  name: 'UnitCityForm',
  props: {
    populateWith: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      city: {},
      isLoading: false,
    };
  },

  created() {
    if (this.populateWith.id) {
      this.city = this.populateWith;
    }
  },

  methods: {
    close() {
      this.$emit('close-dialog');
    },

    async saveCity() {
      try {
        this.isLoading = true;
        const city = await UnitsCityService.create(this.city);
        this.$toast.success('Cidade da Unidade de Atendimento salva com sucesso');
        this.$emit('city-added', city);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.$handleHttpError(e);
      }
    },

    async updateCity() {
      try {
        this.isLoading = true;
        const city = await UnitsCityService.update(this.city);
        this.$toast.success('Cidade da Unidade de Atendimento salva com sucesso');
        this.$emit('city-edited', city);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.$handleHttpError(e);
      }
    },

    submit() {
      if (!this.$refs.form.validate()) return;

      if (this.city.id) {
        this.updateCity();
      } else {
        this.saveCity();
      }
    },
  },
};
</script>

<style scoped>

</style>
